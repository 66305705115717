import * as types from '../action_types'
import { AnyAction } from 'redux'

//定义参数接口
export interface LoginState {
    is_login:boolean
}

//初始化state
let initialState:LoginState = {
    is_login:!!localStorage.getItem('is_login')
}

//返回一个reducer
const loginReducer = (state:LoginState = initialState,action:AnyAction) => {
    switch (action.type){
        case types.LOGIN:
            return {
                is_login:action.is_login
            }
        default:
            return state
    }
}

export default loginReducer