import { createStore,applyMiddleware,StoreEnhancer,StoreEnhancerStoreCreator,Store } from 'redux'
import thunk from 'redux-thunk';
import reducer from './reducers/index';
import { routerMiddleware } from 'react-router-redux';

let createHistory = require('history').createHashHistory;
let history = createHistory();   // 初始化history
let routerWare = routerMiddleware(history);

//生成store增强器
const storeEnhancer:StoreEnhancer = applyMiddleware(thunk,routerWare)
const storeEnhancerStoreCreator:StoreEnhancerStoreCreator = storeEnhancer
(createStore)

const store:Store = storeEnhancerStoreCreator(reducer)

export default store;

