import React,{ useState } from "react";
import { connect } from "react-redux"
import { Layout } from "antd"
import { ac_logout,ac_editpwd } from '../../store/actions'

import LogoutModal from '../logoutModal'
import EditPwdModal from '../editPawModal'

import './index.less'
import localData from "../../utils/storage";

const { Header } = Layout

const TopHeader:React.FC<any> = props => {
    const use_name = localData('get','user_name')
    
    const [ logoutVisible, setLogoutVisible ] = useState(false)
    const [ confirmLoading, setConfirmLoading ] = useState(false)
    
    const [ editVisible, setEditVisible ] = useState(false)
    const [ confirmEditLoading, setConfirmEditLoading ] = useState(false)
    
    // 显示退出登录弹框
    const showModal = () => {
        setLogoutVisible(true)
    }
    // 退出登录提交
    const handleOk = async() => {
        setConfirmLoading(true)
        props.logout()
    }
    // 取消退出登录
    const handleCancel = () => {
        setLogoutVisible(false)
        setConfirmLoading(false)
    }
    
    // 显示修改密码弹框
    const showEditModal = () => {
        setEditVisible(true)
    }
    // 修改密码提交
    const handleEditOk = async (data:any) => {
       props.editPwd({pwd_old:data.oldPwd,pwd_new:data.newPwd})
       setEditVisible(false)
    }
    // 取消修改密码
    const handleEditCancel = () => {
        setEditVisible(false)
        setConfirmEditLoading(false)
        
    }

    return (
        <>
            <Header className="layout-header">
                <div className="header-left"></div>
                <div className="header-right">
                    <span className="info-item user_name">欢迎{use_name}</span>
                    <span className="info-item" onClick={ showEditModal }>修改密码</span>
                    {/* <span className="info-item"><UploadOutlined /></span> */}
                    <span className="info-item logout" onClick={ showModal }>退出</span>
                </div>
            </Header>
            <LogoutModal 
                title = "退出登录"
                width = {400}
                visible= { logoutVisible }
                confirmLoading = { confirmLoading }
                onOk = { handleOk }
                onCancel = { handleCancel }
            />
            <EditPwdModal    
                title = "修改密码"
                width={400}
                visible= { editVisible }
                confirmLoading = { confirmEditLoading }
                onOk = { handleEditOk }
                onCancel = { handleEditCancel }
            ></EditPwdModal>
        </>
    )
}
export default connect(
    (state) => ({}),
    (dispatch:any) => {
        return {
            logout(){
                dispatch(ac_logout())
            },
            editPwd(data:any){
                dispatch(ac_editpwd(data))
            }
        }
    }
)(TopHeader)