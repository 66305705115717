//引入相关的路由及核心
import React, { Component } from 'react'

import Contents from '../../components/content'
import SideMenu from '../../components/sideMenu' //组件的侧边栏，用antd-UI搭建
import TopHeader from '../../components/topHeader' //组件的导航栏，用antd-UI搭建
import BreadCrumbNav from '../../components/breadcrumb'
//antd组件库的配置
import { Layout } from 'antd';

import './index.less'

const { Content } = Layout; //解构

export default class Admin extends Component {
    
    render() {
        return (
            <Layout className="page-layout">

                {/* 顶部信息 */}
                <TopHeader /> 

                <Layout className="site-layout"> 
                    
                    {/* 自定义的sidemenu */}
                    <SideMenu />
                    
                    <Layout className="content-layout">
                        
                        <BreadCrumbNav  />
                        
                        {/* 以下是组件内容区的配置 */}
                        <Content 
                            className="site-layout-background"
                        >
                            <Contents  />
                        </Content>
                        
                    </Layout>
                    
                </Layout>
            </Layout>
        )
    }
}
