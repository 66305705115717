/**
 * login模块接口列表
*/

import { baseUrl ,base } from './base' // 导入接口域名列表

import axios from '../http' // 导入http中创建的axios实例

import qs from 'qs' // 根据需求是否导入qs模块

import { LoginParams } from '../types/basic'

// const baseUrl = '/api' 

const Basic = {
    
    // 登录
    login(params:LoginParams){
        return axios.post(`${baseUrl}/login/login`,qs.stringify(params))
    },

    // 退出
    logout(){
        return axios.post(`${baseUrl}/login/logout`)
    },

    // 修改密码
    editPwd(){
        return axios.post(`${baseUrl}/public/pwd_edit`)
    },

    // 销售团队列表
    getSaleTeam(){
        return axios.get(`${baseUrl}/public/sale-team`)
    }

    
}

export default Basic

