
import { LOGIN } from '../action_types';
import { push, replace } from 'react-router-redux';
import { basic } from '../../request/api'
import { to } from '../../utils/common'
import localData from '../../utils/storage';
import { loginParams } from '../../pages/login'

import { getNewList } from '../../utils/common'
import routeConfig from '../../router/route-config' //引入后端数据
// 登录
export const ac_login = (params:loginParams) => {

    return async (dispatch: any)=>{

        let [ err,res ] =  await to(basic.login(params))

        if(err){

            console.log(err)

        } else {

            let nav = res.data.user.nav.nav;

            let permission = res.data.user.nav.permission;

            localData('set','is_login',true);
            localData('set','user_name',res.data.user.user_name);
            localData('set','user_id',res.data.user.user_id);
            localData('set','group_id',res.data.user.group_id);
            localData('set','menu_list',JSON.stringify(nav));
            localData('set','permission_group',JSON.stringify(permission));

            //根据后端数据，处理路由s
            let menu = getNewList(routeConfig as any,nav);

            localData('set','menu',menu);

            dispatch({type:LOGIN,is_login:true});

            // dispatch(push('/home'));
            dispatch(replace('/home'));
        }
    }
}

// 退出
export const ac_logout = () => {
    return async (dispatch:any)=>{
        let [ err, res ] = await to(basic.logout())
        if(err){
            console.log(err)
        } else {
            localStorage.clear()
            dispatch(push('/login'));
            window.location.reload()
        }
    }
}

// 修改密码
export const ac_editpwd = (data:any) => {
    return async (dispatch:any)=>{
        let [ err, res] = await to(basic.editPwd())
        if(err){
            console.log(err)
        } else {
            localStorage.clear()
            dispatch(ac_logout())
        }
    }
}
