/**
 * 店铺模块接口列表
*/

import { baseUrl ,base } from './base' // 导入接口域名列表

import axios from '../http' // 导入http中创建的axios实例

import { RefundDriverListParams, RefundServiceListParams, RefundQualityListParams, PromotionParams } from '../types/operate'

// const baseUrl = '/api' 
const baseUrl1 = baseUrl + '/refund'
const baseUrl2 = baseUrl + '/promotion'

const Operate = {
    
    // 司机退款退货数据
    getDriverRefundList(params: RefundDriverListParams){
        return axios.get(`${baseUrl1}/driver/list`,{
            params: params
        })
    },

    // 客服退款退货数据
    getServiceRefundList(params: RefundServiceListParams){
        return axios.get(`${baseUrl1}/servicer/list`,{
            params: params
        })
    },

    // 品控退款退货数据
    getQualityRefundList(params: RefundQualityListParams){
        return axios.get(`${baseUrl1}/quality/list`,{
            params: params
        })
    },

    // 促销数据
    getPromotionList(params:any){
        return axios.get(`${baseUrl2}/promotion/list`,{
            params: params
        })
    },

    // 促销类型
    getPromotionType(){
        return axios.get(`${baseUrl2}/promotion/type-list`)
    }
}

export default Operate

