import React, { PureComponent } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from "react-redux"

import { Form, Input, Button } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

import { ac_login } from '../../store/actions/login'

import './index.less'

const FormItem = Form.Item

export interface loginParams {
    username: string;
    password: string;
}

interface Iprops extends RouteComponentProps {
    login:(values:loginParams) => any
}

class Login extends PureComponent<Iprops>{
    constructor(props:any){
        super(props)
    }

    state = {}

    componentDidMount(): void {
        console.log('---------------------游客登录1231231')
        this.getFatherPramas(window.location.href);
    }

    getFatherPramas = (url: string) => {
        let queryString = url.split('?')[1];
        // 如果没有查询参数，返回空对象
        if (!queryString) {
            return {};
        }
        console.log('queryString', queryString)
        if (queryString) {
            this.onFinish({
                username: '孙玉杰',
                password: 'allen116688'
            }) 
        }
    }

    onFinish = async (values:any) => {
        this.props.login(values) 
    }

    onFinishFailed = (errorInfo:any) => {
        console.log('Failed',errorInfo)
    }
    render():React.ReactNode{
        
        return (
            <div id="loginContent">
                <div className="login-logo"></div>
                <div className="login-card-box">
                    <div className="login-card">
                        <div className="login-card-title">
                            欢迎使用翠鲜缘数据报表系统
                        </div>
                        <Form
                            name="login"
                            className="login-form"
                            scrollToFirstError={true}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <FormItem
                                name="username"
                                rules={[{required:true,message:'请输入用户名'}]}
                            >
                                <Input 
                                    className='input-item'
                                    prefix={<UserOutlined className="site-form-item-icon"></UserOutlined>}
                                    placeholder="用户名"
                                    autoComplete='off'
                                />
                            </FormItem>
                            <FormItem
                                name="password"
                                rules={[{required:true,message:'请输入密码'}]}
                            >
                                <Input 
                                    className='input-item'
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="密码"
                                    autoComplete='off'
                                />
                            </FormItem>
                            <FormItem>
                                <Button type="primary" htmlType="submit" className="login-form-button">登录</Button>
                            </FormItem>   
                        </Form>
                    </div>
                </div>
                <div className="login-icp">京ICP备15057049号-1</div>
            </div>
        )
    }
}

export default connect(
    (state) => ({}),
    (dispatch:any) => {
        return {
            login(data:loginParams){
                dispatch(ac_login(data))
            }
        }
    }
)(withRouter(Login))