import React from 'react'
import { Form,Input } from 'antd'

interface fromProps {
    name:string;
    label:string;
    message:string
}
const FormItem:React.FC<fromProps> = (props) =>  {
    return (
        <Form.Item 
            name={ props.name }
            label={`${props.label} ：`}
            rules={[
                {
                    required: true,
                    message:`${props.message}`
                },
                {
                    pattern:/^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)\S{8,}$/,
                    message:'密码长度不符；未包含是数字、字母大、小写、符号中的2种'
                }
            ]}
        >
            <Input 
                className="input-password"
                type="password" 
                autoComplete='new-password'
            />
        </Form.Item>
    )
}

export default FormItem