import React from 'react'
import { Modal } from 'antd';

interface Iprops {
    title: string;
    width?: number;
    visible: boolean;
    confirmLoading: boolean;
    onOk: () => any;
    onCancel: () => any;
    children: JSX.Element;
}
const IModal:React.FC<Iprops> = (props) => {
    return (
        <Modal 
            title={props.title}
            width={props.width}
            visible={props.visible}
            confirmLoading={props.confirmLoading}
            onOk={props.onOk}
            onCancel={props.onCancel}
        >
            {props.children}
        </Modal>
    )
}

export default IModal
