import React from 'react'
import IModal from '../Modal';
import { Form,message } from 'antd'
import FormItem from './formItem'
import './index.less'
interface Iprops {
    title: string;
    width: number;
    visible: boolean;
    confirmLoading: boolean;
    onOk: (values:object) => any;
    onCancel: () => any;
}

const EditPwdModal:React.FC<Iprops> = (props) => {
    
    const [form] = Form.useForm()
    
    const handleOk = async () => {
        try {
            const values = await form.validateFields();

            const reg = /^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)\S{8,}$/;

            let { oldPwd, newPwd, surePwd } = values;

            if(oldPwd == '' || newPwd == '' || surePwd == ''){
                return
            }

            if(!reg.test(oldPwd) || !reg.test(newPwd) || !reg.test(surePwd)){
                return
            }

            if(oldPwd == newPwd){
                message.error('新密码不可与历史密码相同');
                return;
            }

            if(newPwd != surePwd){
                message.error('新密码与确认密码不一致');
                return;
            }

            await props.onOk(values)

            form.resetFields();
            
        } catch (errorInfo){
            console.log('Failed',errorInfo)
        }
    }

    const handleCancel = async () => {
        await props.onCancel()
        form.resetFields();
    }
    return (
        <IModal 
            title={props.title}
            width={props.width}
            visible={props.visible}
            confirmLoading={props.confirmLoading}
            onOk={ handleOk }
            onCancel={ handleCancel }
        >
            <Form form={form} className="edit-password-form">
                <div className="tip">
                    <p>提示：</p>
                    <div>
                        <p className="text">1.密码长度不小于8位</p>
                        <p className="text">2.至少包含数字、字母大、小写、符号中的2种</p>
                    </div>
                </div>
                <FormItem name="oldPwd" label="原密码" message="请填写当前密码" />
                <FormItem name="newPwd" label="新密码" message="新密码不能为空" />
                <FormItem name="surePwd" label="确认密码" message="新密码不能为空" />
            </Form>
        </IModal>
    )
}

export default EditPwdModal
