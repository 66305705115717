import { lazy } from 'react'

// 首页

// import Home from '../pages/home';

// 销售
// import DayData from '../pages/xiaoshou/dayData';
// import DayDataDetail from '../pages/xiaoshou/dayDataDetail';
// import MonthData from '../pages/xiaoshou/monthData';
// import RefundOverView from '../pages/refundview'
// import RefundList from '../pages/refundList'


// // 商品
// import GoodsDay from '../pages/goods'
// import GoodsDetail from '../pages/goods/detail'

// // 店铺
// import StoreData from '../pages/store'
// import StoreDetail from '../pages/store/detail'

// // 客户数据
// import MemberData from '../pages/member'

// // 系统管理
// /// 权限管理
// //// 用户管理
// import UserManage from '../pages/user'
// //// 角色权限管理
// import PermissionManage from '../pages/permission'
// //// 角色权限管理详情
// import PermissionDetail from '../pages/permission/detail'

// // 运营
// /// 退款退货数据
// //// 司机退货退款数据
// import DriverRefund from '../pages/refund/driver_refund'
// //// 客服退货退款数据
// import ServiceRefund from '../pages/refund/service_refund'
// //// 品控退货退款数据
// import QualityRefund from '../pages/refund/quality_refund'
// //// 促销数据
// import PromotionData from '../pages/promotion'

// 首页模块icon

// 首页icon
import indexIcon from '../assets/images/sy.png'

// 销售 icon
import saleIcon from '../assets/images/xs.png'

// 运营 icon
import operateIcon from '../assets/images/yy.png'

// 系统管理 icon
import systemIcon from '../assets/images/xtgl.png'

// 商品 icon
import goodsIcon from '../assets/images/sp.png'

// 用户 icon
import memberIcon from '../assets/images/kh.png'

// 店铺
import storeIcon from '../assets/images/dp.png'

// 首页
const Home = lazy(() => import ("../pages/home"));

// 销售
const DayData = lazy(() => import ("../pages/xiaoshou/dayData"));
const DayDataDetail = lazy(() => import ("../pages/xiaoshou/dayDataDetail"));
const MonthData = lazy(() => import ("../pages/xiaoshou/monthData"));
const RefundOverView = lazy(() => import ("../pages/refundview"));
const RefundList = lazy(() => import ("../pages/refundList"));

// 商品
const GoodsDay = lazy(()=>import ('../pages/goods'));
const GoodsDetail = lazy(()=>import ('../pages/goods/detail'))

// 店铺
const StoreData = lazy(()=>import ('../pages/store')) 
const StoreDetail = lazy(()=>import ('../pages/store/detail'))

// 客户数据
const MemberData = lazy(()=>import ('../pages/member'))

// 系统管理
/// 权限管理
//// 用户管理
const UserManage = lazy(()=>import ('../pages/user'))
//// 角色权限管理
const PermissionManage = lazy(()=>import ('../pages/permission')) 
//// 角色权限管理详情
const PermissionDetail = lazy(()=>import ('../pages/permission/detail'))

// 运营
/// 退款退货数据
//// 司机退货退款数据
const DriverRefund = lazy(()=>import ('../pages/refund/driver_refund'))
//// 客服退货退款数据
const ServiceRefund = lazy(()=>import ('../pages/refund/service_refund')) 
//// 品控退货退款数据
const QualityRefund = lazy(()=>import ('../pages/refund/quality_refund')) 
//// 促销数据
const PromotionData = lazy(()=>import ('../pages/promotion')) 

const routeConfig = [
    {
        path:'/home',
        exact:true, 
        name: "首页",
        nav_alias: "shouye",
        icon:indexIcon,
        component:Home,
        defaultRedirect: true
    },
    {
        path:'/sale',
        exact:true,
        name:'销售',
        nav_alias: "xiaoshou",
        icon:saleIcon,
        component:DayData,
    },
    {
        path:'/sale/data',
        exact:true,
        name:'销售数据',
        nav_alias: "xiaoshoushuju",
        icon:'',
        component:DayData,
    },
    {
        path:"/sale/data/day",
        exact:true,
        name:'日数据',
        nav_alias:'rishuju',
        icon:'',
        component:DayData
    },
    {
        path:"/sale/data/detail/:from/:id/:date",
        exact:true,
        name:'销售数据详情',
        nav_alias:'rishuju',
        icon:'',
        component:DayDataDetail
    },
    {
        path:"/sale/data/month",
        name:'月数据',
        exact:true,
        nav_alias:'yueshuju',
        icon:'',
        component:MonthData
    },
    {
        path:"/sale/refund",
        name:'退货数据',
        exact:true,
        nav_alias:'tuikuantuihuosj',
        icon:'',
        component:RefundOverView
    },
    {
        path:"/sale/refund/index",
        name:'退货数据概览',
        exact:true,
        nav_alias:'tuikuantuihuoshujugailan',
        icon:'',
        component:RefundOverView
    },
    {
        path:"/sale/refund/list",
        name:'退货数据列表',
        exact:true,
        nav_alias:'tuikuantuihuoshujuliebiao',
        icon:'',
        component:RefundList
    },
    {
        path:"/goods",
        name:"商品",
        exact:true,
        nav_alias:'shangpin',
        icon:goodsIcon,
        component:GoodsDay
    },
    {
        path:"/goods/sale",
        name:"销售数据",
        exact:true,
        nav_alias:'shangpinxiaoshoushuju',
        icon:'',
        component:GoodsDay
    },
    {
        path:"/goods/sale/day",
        name:"日数据",
        exact:true,
        nav_alias:'shangpinrishuju',
        icon:'',
        component:GoodsDay
    },
    {
        path:"/goods/sale/month",
        name:"月数据",
        exact:true,
        nav_alias:'shangpinyueshuju',
        icon:'',
        component:GoodsDay
    },
    {
        path:"/goods/sale/detail/:type/:id/:date",
        name:"商品数据详情",
        exact:true,
        nav_alias:'shangpinshujuxiangqing',
        icon:'',
        component:GoodsDetail
    },
    {
        path:"/store",
        name:"店铺",
        exact:true,
        nav_alias:'dianpu',
        icon:storeIcon,
        component:StoreData
    },
    {
        path:"/store/manage",
        name:"店铺管理",
        exact:true,
        nav_alias:'dianpuguanli',
        icon:'',
        component:StoreData
    },
    {
        path:"/store/manage/data",
        name:"店铺数据",
        exact:true,
        nav_alias:'dianpushuju',
        icon:'',
        component:StoreData
    },
    {
        path:"/store/manage/data/detail/:id/:date",
        name:"店铺数据详情",
        exact:true,
        nav_alias:'dianpushuju',
        icon:'',
        component:StoreDetail
    },
    {
        path:"/member",
        name:"用户",
        exact:true,
        nav_alias:'yonghu',
        icon:memberIcon,
        component:MemberData
    },
    {
        path:"/member/manage",
        name:"用户管理",
        exact:true,
        nav_alias:'yonghuguanli2',
        icon:'',
        component:MemberData
    },
    {
        path:"/member/manage/data",
        name:"用户数据",
        exact:true,
        nav_alias:'yonghuliebiao',
        icon:'',
        component:MemberData
    },
    {
        path:"/system",
        name:"系统管理",
        exact:true,
        nav_alias:'xitongguanli',
        icon:systemIcon,
        component:UserManage
    },
    {
        path:"/system/auth",
        name:"权限管理",
        exact:true,
        nav_alias:'quanxianguanli',
        icon:'',
        component:UserManage
    },
    {
        path:"/system/auth/member",
        name:"用户管理",
        exact:true,
        nav_alias:'yonghuguanli',
        icon:'',
        component:UserManage
    },
    {
        path:"/system/auth/role",
        name:"角色权限管理",
        exact:true,
        nav_alias:'juesequanxianshezhi',
        icon:'',
        component:PermissionManage
    },
    {
        path:"/system/auth/role/detail/:id/:type",
        name:"角色权限管理详情",
        exact:true,
        nav_alias:'juesequanxianshezhi',
        icon:'',
        component:PermissionDetail
    },
    {
        path:"/operate",
        name:"运营",
        exact:true,
        nav_alias:'yunying',
        icon:operateIcon,
        component:DriverRefund
    },
    {
        path:"/operate/refund",
        name:"退款退货数据",
        exact:true,
        nav_alias:'tuikuantuihuoshuju',
        icon:'',
        component:DriverRefund
    },
    {
        path:"/operate/refund/driver",
        name:"司机退款退货数据",
        exact:true,
        nav_alias:'sijituikuantuihuoshuju',
        icon:'',
        component:DriverRefund
    },
    {
        path:"/operate/refund/service",
        name:"客服退款退货数据",
        exact:true,
        nav_alias:'kefutuikuantuihuoshuju',
        icon:'',
        component:ServiceRefund
    },
    {
        path:"/operate/refund/quality",
        name:"品控退款退货数据",
        exact:true,
        nav_alias:'pinkongtuikuantuihuoshuju',
        icon:'',
        component:QualityRefund
    },
    {
        path:"/operate/promotion",
        name:"促销数据",
        exact:true,
        nav_alias:'cuxiaoshuju2',
        icon:'',
        component:PromotionData
    },
    {
        path:"/operate/promotion/index",
        name:"促销数据",
        exact:true,
        nav_alias:'cuxiaoshuju',
        icon:'',
        component:PromotionData
    }
]
export default routeConfig