import React, { PureComponent } from 'react'
import { Layout, Menu } from 'antd';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom'
import localData from '../../utils/storage'
const { Sider } = Layout;
const { SubMenu } = Menu;

class SideMenu extends PureComponent<RouteComponentProps> { 
    
    menuList: any;

    constructor(props:any){
        super(props)
        let menu_list = localData('get','menu');
        this.menuList = menu_list ? menu_list : [];
    }

    // 封装渲染函数，遍历侧边栏
    renderMenu = (menus:any)=>{ 
       return menus.map((item:any)=>{
            if(item.children){ 
                return <SubMenu key={item.path} 
                                icon={item.icon?<img src={item.icon} alt="" />:""}
                                title={ 
                                    <span>
                                        <span>{item.nav_name ? item.nav_name :'-'}</span>
                                    </span>
                                }
                        >
                            { this.renderMenu(item.children) }
                                
                        </SubMenu>    
            }else{
            
                return (
                    <Menu.Item key={item.path} 
                        icon={item.icon?<img src={item.icon} alt="" />:""}
                    >
                         <span>{item.nav_name ? item.nav_name :'-'}</span>
                    </Menu.Item>
                )
            }
        })
    }

    // 切换导航
    handleChangePage = (obj:any)=>{  
        this.props.history.push(obj.key)
    }

    render() {
        let selectedKey = this.props.location.pathname 
        
        return (
            <Sider trigger={null} collapsible >
                <Menu theme="dark" mode="vertical" 
                    defaultSelectedKeys={[selectedKey]} 
                    defaultOpenKeys={[]}
                    onClick ={this.handleChangePage}
                >
                    { this.renderMenu(this.menuList) }
                </Menu>
            </Sider>
        )
    }
}

export default withRouter(SideMenu)