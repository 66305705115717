import confirm from "antd/lib/modal/confirm";

// 侧边栏路由数据处理
export const getNewList = (routeConfigList:[],routeList:[]) => {
    return routeList.map((item:any)=>{

            let outObj = {};

            let obj:any = routeConfigList.find((v:any)=>item.nav_info.nav_alias && v.nav_alias == item.nav_info.nav_alias )
           
            if(obj){
                outObj = {
                    ...obj,
                    ...item.nav_info
                }
            } else {
                outObj = {
                    ...item.nav_info
                }
            }
            
            
            //首页特殊处理
            if(item.nav_info && item.nav_info.nav_alias && item.nav_info.nav_alias == 'shouye'){
                return outObj;
            }
            
            // 多级子路由处理
            if(item.children && item.children[0] && item.children[0].nav_info && item.children[0].nav_info.nav_alias){
                let childrenMenu = item.children;
                (outObj as any).children = getNewList(routeConfigList,childrenMenu);
            } 
            return outObj   
    })
}

// 优化promise方法错误处理
export const to = (promise:any) => {
    return promise.then((data:any) => {
       return [null, data];
    })
    .catch((err:any) => [err]);
}

// 格式化数字添加千分位
export const toThousands = ( num: string | number, isinit?:boolean ) => {
    num = typeof num == 'string' ? num 
        :typeof num == 'number' ? num.toString()
        :'';
       
    let data = num.replace(/\d+/, function(n) {
        return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    });
    
    // 是否需要返回整数
    if(isinit){ // 返回整数

        return data.split('.')[0]

    }else { // 返回两位小数
       
        var data_arr = data.split(".");
        if(data_arr.length==1){
            data = data+".00";
            return data;
        }
        if(data_arr.length>1){
            if(data_arr[1].length<2){
                data = data+"0";
            }
            return data;
        }
    }
}

// 判断是否为正整数
export const isInit = (num:string) => {
    if(/^[0-9]*[1-9][0-9]*$/.test(num)){
        return true
    }
}

 // 数据为空时默认显示
export const cell=(str:any)=>{
    if(typeof str == 'number'){
        str = str.toString()
    }
    return str||'---';
};

// 截取路由参数
export const getUrlParam = (name:string,search:string) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");  
    var r = search.substr(1).match(reg);  
    if (r != null) return unescape(decodeURI(r[2])); return null;  
};

// 保留两位小数，不足补0
export const returnFloat=(value:number|string)=>{
    var f = parseFloat(value.toString());
    if (isNaN(f)) {
        return false;
    }

	var num:number = Math.round(parseFloat(value.toString())*100)/100;
    var num_str = num.toString();
    console.log('num_str'+num_str)
	var s = num_str.split(".");
	if(s.length==1){
		num_str = num_str+".00";
		return num_str;
	}
	if(s.length>1){
		if(s[1].length<2){
			num_str = num_str+"0";
		}
		return num_str;
	}
}