/**
 * 店铺模块接口列表
*/

import { baseUrl ,base } from './base' // 导入接口域名列表

import axios from '../http' // 导入http中创建的axios实例

import qs from 'qs' // 根据需求是否导入qs模块

import { EditUserGroup, DelPermission, PermissionInfo, EditPermission } from '../types/system'

// const baseUrl = '/api' 
const baseUrl1 = baseUrl + '/system/user'
const baseUrl2 = baseUrl + '/system/permission'

const System = {
    // 员工列表
    getUserList(params: any){
        return axios.get(`${baseUrl1}/list`,{
            params: params
        })
    },

    // 员工编辑
    editUserGroup(params: EditUserGroup){
        return axios.post(`${baseUrl1}/edit-user-group`,qs.stringify(params))
    },

    // 角色列表
    getPermissionGroupList(params:any){
        return axios.get(`${baseUrl2}/group-list`,{
            params: params
        })
    },

    // 删除角色
    delPermission(params:DelPermission){
        return axios.post(`${baseUrl2}/del`,qs.stringify(params))
    },

    // 角色信息
    getPermissionInfo(params:PermissionInfo){
        return axios.get(`${baseUrl2}/info`,{
            params:params
        })
    },

    // 编辑角色
    editPermission(params:EditPermission,type:string){

        return axios.post(`${baseUrl2}/${type}`,qs.stringify(params))
    }

}

export default System

