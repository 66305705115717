import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from './store'
import './index.css';
import App from './App';
import zh_CN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zh_CN}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);

