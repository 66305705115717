/**
 * 商品模块接口列表
*/

import { baseUrl ,base } from './base' // 导入接口域名列表

import axios from '../http' // 导入http中创建的axios实例

import { GoodsListParams, GoodsDetailParams } from '../types/goods'

// const baseUrl = '/api' 
const baseUrl1 = baseUrl + '/goods/goods'
const baseUrl2 = baseUrl + '/goods/goods-class'

const Goods = {
    // 日数据列表
    getDayList(params: GoodsListParams,from: string){
        return axios.get(`${baseUrl1}/${from == 'day' ? 'day' : 'month'}`,{
            params: params
        })
    },

    // 商品分类
    getGoodsClass(){
        return axios.get(`${baseUrl2}/list`)
    },

    // 商品详情基本信息
    getGoodsDetail(params: GoodsDetailParams){
        return axios.get(`${baseUrl1}/detail`,{
            params: params
        })
    },

    // 商品详情-数据分析
    getGoodsStat(params: GoodsDetailParams){
        return axios.get(`${baseUrl1}/stat`,{
            params: params
        })
    },

    // 商品详情-销量趋势图
    getGoodsSaleTrend(params:GoodsDetailParams){
        return axios.get(`${baseUrl1}/goods-sale-data-trend`,{
            params: params
        })
    },

    // 商品详情-退款退货原因
    getGoodsRefundData(params:GoodsDetailParams){
        return axios.get(`${baseUrl1}/goods-refund-data-pie`,{
            params: params
        })
    }
}

export default Goods

