/**
 * 店铺模块接口列表
*/

import { baseUrl ,base } from './base' // 导入接口域名列表

import axios from '../http' // 导入http中创建的axios实例

import { StoreListParams, StoreDetailBasicParams, StoreDetailParams } from '../types/store'

// const baseUrl = '/api' 
const baseUrl1 = baseUrl + '/store/store'

const Store = {
    // 店铺列表
    getStoreList(params: StoreListParams){
        return axios.get(`${baseUrl1}/list`,{
            params: params
        })
    },

    // 店铺详情--基本信息
    getStoreDetail(params: StoreDetailBasicParams){
        return axios.get(`${baseUrl1}/detail`,{
            params: params
        })
    },

    // 店铺详情--数据分析
    getStoreStat(params: StoreDetailParams){
        return axios.get(`${baseUrl1}/stat`,{
            params: params
        })
    },

    // 店铺详情--销量趋势图
    getStoreSaleTrend(params:StoreDetailParams){
        return axios.get(`${baseUrl1}/store-sale-data-trend`,{
            params: params
        })
    },

    // 店铺详情-退款退货原因
    getStoreRefundData(params:StoreDetailParams){
        return axios.get(`${baseUrl1}/store-refund-data-pie`,{
            params: params
        })
    }
}

export default Store

