/****
* name:localStorage的key
* data:localStorage的Value
* expire:localStorage的过期时间,默认是1天后过期 
* = new Date().getTime() + expire * 60 * 60 * 1000
****/
//设置缓存
export const localStorageSet = (name:string, data:any, expire:any) => {
    expire = new Date().getTime() + expire * 60 * 60 * 1000;
    const obj = {
      data,
      expire
    };
    localStorage.setItem(name, JSON.stringify(obj));
};
  
// 读取缓存
export const localStorageGet = (name:string) => {
    const storage = localStorage.getItem(name);
    const time = new Date().getTime();
  
    let result = '';
    if (storage) {
      const obj = JSON.parse(storage);
      if (time < obj.expire) {
        result = obj.data;
      } else {
        localStorage.removeItem(name);
      }
    }
    return result;
};