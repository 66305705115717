/**
 * 店铺模块接口列表
*/

import { baseUrl ,base } from './base' // 导入接口域名列表

import axios from '../http' // 导入http中创建的axios实例

import { MemberListParams } from '../types/member'

// const baseUrl = '/api' 
const baseUrl1 = baseUrl + '/member/member'

const Member = {
    // 店铺列表
    getMemberList(params: MemberListParams){
        return axios.get(`${baseUrl1}/list`,{
            params: params
        })
    }
}

export default Member

