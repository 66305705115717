/**
 * 销售模块接口列表
*/

import { baseUrl ,base } from './base' // 导入接口域名列表

import axios from '../http' // 导入http中创建的axios实例

import { SaleDayListParams, SaleDetailParams, SaleMonthListParams, RefundParams, RefundReasonParams, RefundList } from '../types/sale'

// const baseUrl = '/api' 
const baseUrl1 = baseUrl + '/sale/day'
const baseUrl2 = baseUrl + '/sale/month'
const baseUrl3 = baseUrl + '/refund'

const Sale = {
    // 日数据列表
    getDayList(params: SaleDayListParams){
        return axios.get(`${baseUrl1}/list`,{
            params: params
        })
    },

    // 月数据列表
    getMonthList(params:SaleMonthListParams){
        return axios.get(`${baseUrl2}/list`,{
            params: params
        })
    },

    // 上方统计数据
    getUpStat(params: SaleDetailParams,from:string){
        return axios.get(`${from=='date'?baseUrl1:baseUrl2}/up-stat`,{
            params: params
        })
    },

    // 销售数据趋势
    getDataTrend(params:SaleDetailParams,from:string){
        return axios.get(`${from=='date'?baseUrl1:baseUrl2}/sale-data-trend`,{
            params: params
        })
    },

    // 销售排行
    getSaleRank(params:SaleDetailParams,from:string){
        return axios.get(`${from=='date'?baseUrl1:baseUrl2}/sale-rank`,{
            params: params
        })
    },

    // 销售目标达成
    getSaleTargetFinish(params:SaleDetailParams,from:string){
        return axios.get(`${from=='date'?baseUrl1:baseUrl2}/sale-target-finish`,{
            params: params
        })
    },

    // 月数据目标销售达成
    getSaleGoleTarget(params:SaleDetailParams,from:string){
        return axios.get(`${baseUrl2}/sale-current-month-target-finish`,{
            params: params
        })
    },
    // 退货数据概览
    getRefundInfo(params:RefundParams){
        return axios.get(`${baseUrl3}/home/info`,{
            params: params
        })
    },

    // 退款退货原因
    getRefundReason(params:RefundParams){
        return axios.get(`${baseUrl3}/home/refund-reason-rate`,{
            params: params
        })
    },

    // 各指标占比排行-退货条数
    getReturnRowList(params:RefundParams){
        return axios.get(`${baseUrl3}/member/return-row-list`,{
            params: params
        })
    },

    // 各指标占比排行-售后退货退款条数
    getRefundAfterList(params:RefundParams){
        return axios.get(`${baseUrl3}/member/refund-after-list`,{
            params: params
        })
    },

    // 各指标占比排行-售后退货退款条数
    getRefundNoReasonList(params:RefundParams){
        return axios.get(`${baseUrl3}/member/refund-no-reason-list`,{
            params: params
        })
    },

    // 退款退货原因对比
    getRefundReasonRate(params:RefundReasonParams){
        return axios.get(`${baseUrl3}/home/refund-reason-data`,{
            params: params
        })
    },

    // 退货数据列表
    getRefundList(params:RefundList){
        return axios.get(`${baseUrl}/sale/refund/list`,{
            params: params
        })
    }
}

export default Sale

