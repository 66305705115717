import { OPENPAGELOADING, CLOSEPAGELOADING } from '../actions/loading'
//定义参数接口
export interface LoadingState {
    pageLoadingVal:boolean
}
const initState:LoadingState = {
  pageLoadingVal: false
}

const LoadingReducer = (state=initState, action:any) => {
    switch (action.type) {
      case OPENPAGELOADING: {
        return {
          pageLoadingVal: true
        }
      }
      case CLOSEPAGELOADING: {
        return {
          pageLoadingVal: false
        }
      }
      default: {
        return state
      }
    }
  }
  export default LoadingReducer