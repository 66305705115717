/**
 * 首页模块接口列表
*/

import { baseUrl ,base } from './base' // 导入接口域名列表

import axios from '../http' // 导入http中创建的axios实例

import { BasicParams, OrderSpradParams } from '../types/home'

// const baseUrl = '/api' 
const baseUrl1 = baseUrl + '/index/index'
const baseUrl2 = baseUrl + '/member/member'

const Home = {
    
    // 上方统计数据
    getUpStart(params: BasicParams){
        return axios.get(`${baseUrl1}/up-stat`,{
            params: params
        })
    },

    // 总订单来源
    getOrderFrom(params: BasicParams){
        return axios.get(`${baseUrl1}/order-from`,{
            params: params
        })
    },

    // 销售 ｜ 总订单分布
    getOrderSprad(params: OrderSpradParams){
        return axios.get(`${baseUrl1}/order-sprad`,{
            params: params
        })
    },

    // 销售数据趋势
    getSaleDataTrend(params: BasicParams){
        return axios.get(`${baseUrl1}/sale-data-trend`,{
            params: params
        })
    },

    // 销售目标完成情况
    getSaleTargetCompletion(params: BasicParams){
        return axios.get(`${baseUrl1}/sale-target-completion`,{
            params: params
        })
    },

    // 销售团队数据对比
    getSaleCompare(params: BasicParams){
        return axios.get(`${baseUrl1}/sale-compare`,{
            params: params
        })
    },

    // 销售排行
    getSaleRank(params: BasicParams){
        return axios.get(`${baseUrl1}/sale-rank`,{
            params: params
        })
    },

    // 客户数据
    getMemberStat(params: BasicParams){
        return axios.get(`${baseUrl2}/stat`,{
            params: params
        })
    },

    // 退款退货原因
    getRefundReason(params: BasicParams){
        return axios.get(`${baseUrl1}/refund-reason-data`,{
            params: params
        })
    }
}

export default Home

