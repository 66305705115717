import { combineReducers,ReducersMapObject,AnyAction,Reducer } from 'redux'
import loginReducer,{ LoginState } from './login'
import LoadingReducer,{ LoadingState } from './loading'

// 合并reducers,模块化
export interface CombinedState {
    loginReducer:LoginState,
    LoadingReducer:LoadingState
}
const reducers:ReducersMapObject<CombinedState,AnyAction> = {
    loginReducer,
    LoadingReducer
}
const reducer:Reducer<CombinedState,AnyAction> = combineReducers(reducers)

export default reducer;