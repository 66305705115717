import React from "react"
import { Link, withRouter } from 'react-router-dom'

import { Breadcrumb } from "antd"
import { PageHeader } from 'antd'

import '../../assets/less/breadcrumb.less'
import routeConfig from '../../router/route-config'

//详情路由单独处理
const DetailRouteName:any = {
   '/sale/data/detail/day':'日数据详情',
   '/sale/data/detail/month':'月数据详情',
}


const BreadCrumbNav:React.FC<any> = props => {
    const { location } = props;

    //路由扁平化
    
    let menuList:any = routeConfig;
    

    const pathSnippets = location.pathname.split("/").filter((i:string)=>i)
   
    const extraBreadcrumbItems = 
        <Breadcrumb>
            {
                 pathSnippets.map((_:string,index:number)=>{
                   let temp = pathSnippets.slice(0,index+1);
                   const url = '/' + temp.reduce((accumulator:any, currentValue:any)=>{
                        return `${accumulator}/${currentValue}`
                   })
                   
                    let obj:any = menuList.find((v:any)=>v.path === url )
                   
                    if(obj){
                        return (
                            <Breadcrumb.Item key={url}>
                                {
                                   index <= 1 ? obj.name : <Link to={obj.path}>{obj.name}</Link>
                                }
                            </Breadcrumb.Item>
                       )
                    }else if(DetailRouteName[url]){
                        return (
                        <Breadcrumb.Item key={url}>
                            {DetailRouteName[url]}
                        </Breadcrumb.Item>
                        )
                    }else{
                        return (
                            <Breadcrumb.Item key={url}>
                                {/* --- */}
                            </Breadcrumb.Item>
                       )
                    }
                })
            }
        </Breadcrumb>            
    return (
        <PageHeader
            className="site-page-header"
            breadcrumb={ extraBreadcrumbItems }
        />
    )
}

export default withRouter(BreadCrumbNav)