// 登录
export const LOGIN = 'LOGIN';
export type LOGIN = typeof LOGIN;

// 退出
export const LOGOUT = 'LOGOUT';
export type LOGOUT = typeof LOGOUT;

// 修改密码
export const EDITPWD = 'EDITPWD';
export type EDITPWD = typeof EDITPWD;

// 首页密码过期修改密码
export const INDEXGETINFO = 'INDEXGETINFO';
export type INDEXGETINFO = typeof INDEXGETINFO;

