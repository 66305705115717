/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
*/
import axios from 'axios' // 引入axios

import store from '../store'
import { OPENPAGELOADING, CLOSEPAGELOADING } from '../store/actions/loading'

import { message } from 'antd'

/**
 * 跳转登录页
 * 携带当前的页面路由，已在登录页面完成登录后返回当前页面
*/
const toLogin = () => {
    
    // 清除登录信息
    localStorage.clear()

    // 刷新页面跳转至登录页
    setTimeout(() => window.location.reload(), 0);
}

/**
 * 请求失败后的错误统一处理
 * @params {Number} status 请求失败的状态码
*/
const errorHandle = (status:number, other:string)=>{
    // 状态码判断
    switch (status){
        // 403 token过期
        // 登录过期对用户进行提示
        // 清楚本地token
        // 跳转登录页面
        case 403:
            message.error('登录过期请重新登录')
            toLogin()
            break;

        // 请求不存在
        case 404:
            message.error('网络请求不存在')
            break;

        // 其他错误请求
        default:
            message.error(other)
            break;
    }
}

// 创建 axios 实例
var instance = axios.create({ timeout: 1000 * 12})

// 设置 post 请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 当前正在请求接口数量
let requestArr: Array<any> = [];

const handleErrorReq = (type: string, arr: any) => {
    if (arr.length > 0) {
        errorHandle(type === 'err' ? arr[0].status : arr[0].code, arr[0].message);
    }
}

// 当前需要loading的请求的数量
let needLoadingRequestCount = 0;

function showLoading(){
    if (needLoadingRequestCount === 0) {
        store.dispatch({type: OPENPAGELOADING})
    }
    needLoadingRequestCount++
}

// 隐藏loading
function hideLoading () {
    if(needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount == 0) {
        store.dispatch({type: CLOSEPAGELOADING})
    }
}

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 * */ 
instance.interceptors.request.use(
    config => {
       // 登录流程控制中，根据本地是否存在token判断用户的登录状态
       // 但是即使token存在，也可能token是过期的，所以在每次的请求头中携带token
       // 后台根据携带的token判断用户的登录状态，并返回对应的状态码
       // 而后可以在响应拦截器中，根据状态码进行一些统一的操作
        const token = 'df043ade68528c069c92ff182f9c9e51'
        if(token){
            config.headers.Authorization = token
        }

        showLoading()

        return config
    },
    error =>{
       
        hideLoading()

        return Promise.reject(error)
    } 
)

// 响应拦截器
instance.interceptors.response.use(
    
    // 请求成功 
    res => {
        hideLoading()
        const { data, status } = res
        requestArr.push(data);

        if(status == 200){
            if(data.code == 200){
               return Promise.resolve(data)
            } else {
                const errorArr = requestArr.filter((item: any) => item.code !== 200);
                if (errorArr.length === 1) {
                    handleErrorReq("succee", errorArr);
                }
                return Promise.reject(res)
            }

        } else {
            return Promise.reject(res)
        }
    },
   
    // 请求失败
    error => {
        hideLoading()
        const { response } = error;
        requestArr.push(response);
       
        if(response){
            if (requestArr.length === 1) {
                handleErrorReq('err', requestArr);
            }
            return Promise.reject(response)
        } else {
            
            // 处理断网的情况
            return Promise.reject(error)
        }
    }
)

export default instance
