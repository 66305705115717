import React from 'react'
import IModal from '../Modal';

interface Iprops {
    title: string;
    width?: number;
    visible: boolean;
    confirmLoading: boolean;
    onOk: () => any;
    onCancel: () => any;
}
const LogoutModal:React.FC<Iprops> = (props) => {
    return (
        <IModal 
            title={props.title}
            width={props.width}
            visible={props.visible}
            confirmLoading={props.confirmLoading}
            onOk={props.onOk}
            onCancel={props.onCancel}
        >
           <p>确定退出登录？</p>
        </IModal>
    )
}

export default LogoutModal
