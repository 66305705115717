import React, { PureComponent, Suspense } from 'react' //引入核心
import {Route,Redirect,Switch} from 'react-router-dom'//引入路由
import routeConfigList from '../../router/route-config' //引入后端数据
class Contents extends PureComponent { //组件
    
    render () {
        return (
            <Suspense fallback={<p>加载中......</p>}>
                <Switch>
                    {
                        routeConfigList.map((item,key)=>{
                            return <Route exact key={item.nav_alias} path={item.path} component={item.component}></Route>
                        })
                    }
                    <Redirect from="/*" to="/home"></Redirect>
                </Switch> 
            </Suspense>
        )
    }
}
export default Contents