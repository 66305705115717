import React,{ Component } from 'react'

import Router from '../src/router'

import store from './store'

import { Spin } from 'antd';

import { localStorageSet, localStorageGet } from './utils/exprieStorage'

import './App.less'
class App extends Component {
    state = {
	    loading: false
	}
    componentDidMount () {
      // 监听store中pageLoadingVal值
      store.subscribe(() => {
        let storeState = store.getState()
        this.setState({
          loading: storeState.LoadingReducer.pageLoadingVal
        })
      })
    }
    
    componentDidCatch(error:any, info:any) {
        console.log('Error::::', error);
        console.log('info', info);

        // 当前刷新次数
        let reload_count_str = localStorageGet('reload_count');
        let reload_count = reload_count_str ? Number(reload_count_str) : 0;

        // 报错且刷新次数小于3，刷新页面
        if (String(error).includes('Loading chunk') && reload_count < 3) {
            reload_count_str =  (reload_count+1).toString();
            localStorageSet('reload_count',reload_count_str, 1);
            window.location.reload();
        }
    }

    render(){
        let { loading } = this.state
        return (
            <Spin spinning={loading} wrapperClassName="page-loading">
                <Router />
            </Spin>
        )
    }
}

export default App;
