/**
 * 接口域名管理
*/
const isdevelop = false; // 是否是开发环境 开发环境：true  测试环境：false

const base = {
    dev:'http://192.168.0.243:8091',
    pro:'http://218.241.151.238:8091'
}

const baseUrl = isdevelop ? './api' : ''; // 代理URL

export {
    base,
    baseUrl
} 