import React, { Component } from 'react'
import { HashRouter,Switch,Route,Redirect } from 'react-router-dom';
import Login from '../pages/login';
import Admin from '../pages/admin';
export default class Router extends Component {
    componentDidCatch(error:any, info:any) {
      console.log('Error::::', error);
      console.log('info', info);
      if (String(error).includes('Loading chunk')) {
          window.location.reload();
      }
    }

    render() {
      return (
          <HashRouter>
            <Switch>
              <Route path="/login" component={Login}/>
              <Route path="/"  render={()=>
                  localStorage.getItem("is_login_str")?
                  <Admin />
                  :<Redirect to="/login" />
              } />
            </Switch> 
          </HashRouter>
      )
    }
}



